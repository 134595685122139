import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { RequireAuth } from './components/AuthProvider';
import Skeleton from './components/Skeleton';

type RoutesChildrenProps = {
  path: string;
  element: React.FC;
  children?: RouteObject[];
  meta?: {
    name: string;
    system?: 'sales' | 'user';
    auth?: boolean;
    login?: string;
  };
};

const routesChildren: RoutesChildrenProps[] = [
  {
    path: '/',
    element: React.lazy(() => import('./pages/Home')),
    meta: {
      name: '权益',
    },
  },
  {
    path: '/vip/:productId',
    element: React.lazy(() => import('./pages/VIP')),
    meta: {
      name: '使用权益',
    },
  },
  {
    path: '/order',
    element: React.lazy(() => import('./pages/Order')),
    meta: {
      name: '订单列表',
    },
  },
  {
    path: '/vip-card/:type',
    element: React.lazy(() => import('./pages/VipCard')),
    meta: {
      name: '开通会员',
    },
  },
  {
    path: '/order/:orderId',
    element: React.lazy(() => import('./pages/OrderInfo')),
    meta: {
      name: '订单详情',
    },
  },
  {
    path: '/order-checkout',
    element: React.lazy(() => import('./pages/OrderCheckout')),
    meta: {
      name: '收银台',
    },
  },
  {
    path: '/pay-result',
    element: React.lazy(() => import('./pages/PayResult')),
    meta: {
      name: '支付结果',
    },
  },
  {
    path: '/ticket/:ticketId',
    element: React.lazy(() => import('./pages/TicketInfo')),
    meta: {
      name: '券码详情',
    },
  },
  {
    path: '/life',
    element: React.lazy(() => import('./pages/Life')),
    meta: {
      name: '生活权益',
    },
  },
  {
    path: '/test-pay',
    element: React.lazy(() => import('./pages/TestPay')),
    meta: {
      name: '支付测试',
    },
  },
];

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Outlet />,
    children: routesChildren.map((route) => ({
      index: route.path === '/',
      path: route.path,
      element: (
        <React.Suspense fallback={<Skeleton title={route.meta?.name} />}>
          {/* {route.meta!.auth ? (
            <RequireAuth
              system={route.meta?.system}
              login={route.meta?.login}
              children={React.createElement(route.element)}
            />
          ) : (
            React.createElement(route.element)
          )} */}
          {React.createElement(route.element)}
        </React.Suspense>
      ),
      children: route.children,
    })),
  },
];

export default routes;
