import React from 'react';
import { useRoutes } from 'react-router-dom';
import { AuthProvider } from './components/AuthProvider';
import routes from './routes';
import './App.scss';

function App() {
  const element = useRoutes(routes);
  return <AuthProvider>{element}</AuthProvider>;
}

export default App;
