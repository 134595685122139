import React from "react";
import { Skeleton } from 'antd-mobile';

const SkeletonPage: React.FC<{ title?: string }> = ({ title }) => {
  if (title) {
    window.document.title = title;
  }
  return (
    <>
      <Skeleton.Title animated />
      <Skeleton.Paragraph lineCount={5} animated />
      <Skeleton.Title animated />
      <Skeleton.Paragraph lineCount={5} animated />
      <Skeleton.Title animated />
      <Skeleton.Paragraph lineCount={5} animated />
    </>
  );
};

export default SkeletonPage;
