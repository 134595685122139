import React from 'react';

interface AuthContextType {
  user: any;
  signin: (user: string, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

const HandlerAuthProvider = {
  isAuthenticated: false,
  signin(callback: VoidFunction) {
    HandlerAuthProvider.isAuthenticated = true;
    setTimeout(callback, 1000); // 模拟异步
  },
  signout(callback: VoidFunction) {
    HandlerAuthProvider.isAuthenticated = false;
    setTimeout(callback, 1000); // 模拟异步
  },
};

const AuthContext = React.createContext<AuthContextType>(null!);

function useAuth() {
  return React.useContext(AuthContext);
}


export { HandlerAuthProvider, useAuth };
export default AuthContext;
