import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import AuthContext, { useAuth, HandlerAuthProvider } from '../../utils/auth';

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<any>(null);
  console.log('into');

  // setUser(newUser);
  const signin = (newUser: string, callback: VoidFunction) => {
    return HandlerAuthProvider.signin(() => {
      setUser(newUser);
      callback();
    });
  };

  const signout = (callback: VoidFunction) => {
    return HandlerAuthProvider.signout(() => {
      setUser(null);
      callback();
    });
  };

  const value = { user, signin, signout };
  return <AuthContext.Provider value={value} children={children} />;
}

function RequireAuth({
  system,
  login,
  children,
}: {
  system?: 'sales' | 'user';
  login?: string,
  children: JSX.Element;
}) {
  const auth = useAuth();
  const location = useLocation();
  console.log('111222333');
  if (!auth.user) {
    // const system = window.localStorage.getItem('system');
    let user = window.localStorage.getItem('salesId') as string;
    let url = '/login';
    console.log('user', user);
    if (system === 'user') {
      url = '/user-login';
      user = window.localStorage.getItem('userId') as string;
    }
    if (login) {
      url = login;
    }

    if (user) {
      auth.signin(user, () => {});
      return children;
    }
    return <Navigate to={url} state={{ from: location }} replace />;
  }
  return children;
}

export { useAuth, RequireAuth, AuthProvider };
